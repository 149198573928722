/* LARGE SCREENS */

/* SCREEN SIZE: 800px and larger */
@media screen and (min-width: 800px) {
    .body-container {
      margin: 75px 50px;
    }
    .name-title {
      margin-bottom: 20px;
      font-size: 26px;
    }
    .title-container {
      margin-bottom: 20px;
    }
    .header-container {
      max-width: 1100px;
      font-family: "Lato";
      text-align: center;
      height: 80px;
    }
    .navbar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 50px;
    }
    .navlinks {
      display: flex; 
      flex-direction: row;
      padding-left: 0px;
    }  
    .navlink-styles {
      margin: 0px 20px;
      white-space: nowrap;
      text-decoration: none;
      font-size: 17px;
      color: var(--special-text);
      text-shadow:none;
    }
    
    .navlink-styles.active {
        border-bottom-color: black;
        border-bottom-style: solid;
        border-bottom-width: 3px;
        padding-bottom: 3px;
      }
      
      .navlink-styles:hover {
        font-weight: bolder;
        color: black;
      }

    /* hamburger links HIDE */
    .hamburger-container {
      display: none;
    }
  
    /* homepage */
    .about {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  
    .about-text {
      margin-left: 40px;
      margin-right: 10px;
    }
  
    .about-headshot {
      width: 300px;
      object-fit: contain;
    }

    .page-title {
      margin-top: 25px;
    }
  
    .img-container {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .carousel-container {
        margin-top: 30px;
        width: 800px;
    }
    .slide-size {
        width: 100%;
    }
    .subtitle {
      font-size: 18px;
    }
    /* research page */
    .research-text {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
    }
    .research-para-margin {
      margin-right: 20px;
    }
    /* research group */
    .title-spacer {
      margin-top: 35px;
    }
    .student-profile {
      display: flex; 
      flex-direction: row;
      margin-bottom: 75px;
      margin-top: 50px;
    }
    .student-headshot {
      width: 200px;
      object-fit: initial;
      margin-top: 0px;
      margin-right: 30px;
    }
    .student-title-center {
      text-align:left;
      font-weight: bold;
    }
    /* awards and honors */
    .awards-container {
      line-height: 35px;
    }
  }
  
/* SCREEN SIZE - DESKTOP: 1100 and larger */
@media screen and (min-width: 1100px) {
    .navbar-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .title-container {
      display: flex;
      margin-top: -20px;
  }
    
    .navlinks {
        margin-top: 15px;
    }
    
    .about-headshot {
        width: 350px;
        object-fit: contain;
        margin-top: 10px;
    }
    /* research */
    .carousel-container {
      margin-top: 30px;
      padding-right: 10px;
      width: 1100px;
  }
  .body-container {
    max-width: 1100px;
    margin: auto;
}
.navbar-container {
    margin-top: 10px;
}
}