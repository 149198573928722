@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap);
@import url(https://cdn.jsdelivr.net/gh/jpswalsh/academicons@1/css/academicons.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.8.1/font/bootstrap-icons.min.css);

/* SMALL SCREEN SIZE - MOBILE FIRST! */

:root {
  --special-text: #085195;
}
.color {
  color: var(--special-text);
}
body {
  margin: 0px;
}
.body-container {
  margin: 17px 20px;
  font-family: "Lato";
  min-height: calc(100vh - 240px);
}
.mb-50 {
  margin-bottom: 50px;
}
/* header */
.header-container {
  margin: 18px 0px;
  font-family: "Lato";
  text-align: center;
  height: 60px;
}
footer {
  margin-top: 50px;
  height: 75px;
  padding: 20px;
  background-color: rgb(221, 233, 248);
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* privacy policy */
.privacy-policy {
  margin-left: 15px;
  text-decoration: none;
  color: rgb(91, 88, 88);
}
.privacy-title {
  font-size: 25px;
}
.privacy-policy-headings {
  margin-top: 45px;
}
li.bullet-points {
  list-style:disc;
}
/* this keeps footer at the bottom (in combination with body-container) */
.footer-pin {
  position: relative;
  left: 0;
  bottom: 0;
}
.title-container {
  margin-top: -3px;
}
.flex-center {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
ul li {
  list-style:none;
}
ol {
  line-height: 30px;
}
.break {
  margin-bottom: 15px;
}
.page-title {
  font-size: 24px;
  font-weight: bold;
}
.subtitle {
  font-size: 20px;
}

.gray-button {
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
  box-shadow: 1px 1px 3px black;
  font-family: "Lato";
}

.gray-button:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px black;
}

.gray-button-sm {
  padding: 5px;
  border-radius: 6px;
  margin: 0px 5px;
  border: none;
  box-shadow: 1px 1px 2px black;
  font-family: "Lato";
}

.gray-button-sm:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px black;
}

.center {
  text-align: center;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 35px;
}

.mt-25 {
  margin-top: 25px;
}

/* Gian's name on homepage */
.name-title {
  font-size: 20px;
  text-decoration: none;
  color: black;
  white-space: nowrap;
  margin-top: 10px;
}

.name-title.active {
  text-decoration: none;
}

.name-title:hover {
  color: var(--special-text);
  font-weight: bold;
}

/* regular links and hamburger links (small devices) */
#small-url {
  font-size: 14.5px;
}

.link-styles {
  white-space: nowrap;
  text-decoration: none;
  font-size: 16px;
  color: var(--special-text);
  line-height: 65px;
}

.link-styles.active {
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  padding-bottom: 3px;
}

.link-styles:hover {
  font-weight: bolder;
  color: black;
}

.reg-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--special-text);
}

.reg-link:hover {
  font-weight: bolder;
  color: black;
}

.url-link {
  text-decoration: none;
  font-size: 16px;
  color: var(--special-text);
  word-break: break-all;
}

.url-link:hover {
  font-weight: bolder;
  color: black;
}

/* hamburger */
.hamburger-container {
  text-align: center;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
}

.hamburgerlink-container {
  text-align: center;
}

 /* hamburger button */
 .hamburger {
  position: absolute;
  top: 43px;
  right: 40px;
  width: 35px; 
  height: 25px;
}
.hamburger:hover {
  cursor: pointer;
}
.hamburger .bar {
  height: 6px; 
  background-color: var(--special-text);
  margin-bottom: 5px;
  transition: 0.4s;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

/* homepage - about */
.about-headshot {
  width: 50%;
  margin-left: 0px;
  margin-right: 0px;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 45px;
}

p {
  line-height: 35px;
  font-size: 16px;
}

.small-icons {
  font-size: 22px;
  margin: 0px 15px;
  color: black;
}

.small-icons:hover {
  text-shadow: 1px 0px 3px var(--special-text);
}

.connect {
  margin-top: 25px;
  text-align: center;
  color: black;
}


/* research page */
.carousel-container {
  width: 100%;
}
.slide-size {
  width: 100%;
  border-radius: 10px;
}
.research-para {
  margin-bottom: 40px;
}
.publication-subtitle {
  margin-top: 50px;
}

.publication-subtitle-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.download-link {
  font-size: 18px;
  margin: 0px 5px;
  color: var(--special-text);
}
.download-link:hover {
  text-shadow: 1px 0px 2px black;
  font-size: 22.5px;
  color: black;
}

/* research group page */
.student-headshot {
  width: 50%;
  margin-left: 0px;
  margin-right: 0px;
}
.student-profile {
  margin-bottom: 65px;
}
.student-title-center {
  text-align: center;
  font-weight: bold;
}

 /* awards and honors */
 .awards-container {
  line-height: 25px;
}
.diversity-links {
  line-height: 35px;
}

.border-style {
  padding: 20px;
  border-radius: 8px;
  border-left: 4mm ridge rgba(50, 115, 220, 0.6);
  background-color: aliceblue;
}

.card-header .fa {
  transition: .3s transform ease-in-out;
}
.card-header .collapsed .fa {
  transform: rotate(90deg);
}

.collapsible-header {
  color: black;
  text-decoration: none;
}

.fa.pull-right {
  margin: 10px 2px 0px 0px;
}